<template>
  <div class="card card-flush">
    <div class="card-header mt-1">
      <div class="card-title">
        <h1></h1>
      </div>
    </div>
    <div class="card-body pt-0">
      <div id="redoc-container"></div>
    </div>
  </div>
</template>

<script>
import { onMounted } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useRoute } from "vue-router";

export default {
  name: "ApiDocs",
  data() {
    return {
      apiId: null,
    };
  },
  mounted() {
    // if (localStorage.getItem("reloaded")) {
    //   localStorage.removeItem("reloaded");
    // } else {
    //   localStorage.setItem("reloaded", "1");
    //   location.reload();
    // }
  },

  unmounted() {
    window.disableSwagger()
  },

  setup() {
    onMounted(() => {
      const route = useRoute();
      let apiID = route.params.type;
      let category = route.params.category;
      let title = category + " : " + apiID;
      setCurrentPageBreadcrumbs(title, ["API Documentation"]);

      window.initTry("apis/" + apiID + ".yaml");
    });
  },
};
</script>